import React from 'react'
import { Row, Col, Card, Form, Button, Breadcrumb } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout';
import { useState } from 'react';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { API } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { error } from 'jquery';
import Checkvalidator from '../Checkvalidator';

const ImagesAdd = () => {
    const Redirect = useNavigate()
    const [loading, Setloading] = useState(false)
    const [errors, setErrors] = useState({})
    const [Data, SetData] = useState({
        name: "",
        image: "",
        status: 0
    })

    const updateinput = (e) => {
        SetData({ name: e.target.value, image: Data.image, status: Data.status })
        if (!!errors[e.target.name]) setErrors({
            ...errors,
            [e.target.name]: null
        })
    }

    const updateimage = (e) => {
        SetData({ name: Data.name, image: e.target.files[0], status: Data.status })
        if (!!errors[e.target.name]) setErrors({
            ...errors,
            [e.target.name]: null
        })
    }

    const updatestatus = (e) => {
        SetData({ name: Data.name, image: Data.image, status: (e === true) ? 1 : 0 })
    }

    const save = async () => {
        if (Cookies.get('jwt')) {
            const jwt = Cookies.get('jwt')
            const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redirect('/')
            }
            else {
                const newErrors = Checkvalidator(Data,"","")
                if (Object.keys(newErrors).length > 0) {
                    Setloading(false)
                    setErrors(newErrors)
                }
                else {
                    Setloading(true)
                    const Form = new FormData()
                    Form.append('name', Data.name)
                    Form.append('image', Data.image)
                    Form.append('status', Data.status)
                    const Response1 = await API.post('/NewsCategory', Form)
                    if (Response1.data.response_code === 200) {
                        Setloading(false)
                        toast.success(Response1.data.response_message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 1000,
                            closeOnClick: true
                        });
                        Redirect('/Category')

                    }
                    else {
                        Setloading(false)
                        toast.error(Response1.data.response_message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 1000,
                            closeOnClick: true,
                        });
                    }
                }
            }
        } else {
            Redirect('/')
        }
    }

    const checkAuth = async () => {
        if (Cookies.get('jwt')) {
            const jwt = Cookies.get('jwt')
            const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redirect('/')
            }
        } else {
            Redirect('/')
        }
    }

    useEffect(() => {
        checkAuth()
    }, [])


    return (
        <>
            {(loading === true) ? <div className="loader" ></div> : <></>}
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3>News Category Add</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/Category">News Category Lists</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create News Category</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className="page-content">
                    <Card>
                        <Form>
                            <Card.Body>
                                <Row>
                                    <Col sm={4}>
                                        <Form.Group className='form-group'>
                                            <Form.Label htmlFor="cat_name">Name</Form.Label>
                                            <Form.Control id="cat_name" type="text" name="name" className="my-2" onChange={(e) => { updateinput(e) }} isInvalid={!!errors.name} required />
                                            <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group className='form-group'>
                                            <Form.Label htmlFor="cat_image">Image</Form.Label>
                                            <Form.Control id="cat_image" type="file" className="my-2" name="image" onChange={(e) => { updateimage(e) }} isInvalid={!!errors.image} required />
                                            <Form.Control.Feedback type='invalid'>{errors.image}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group className='form-group'>
                                            <Form.Label className="d-block mb-2">Status</Form.Label>
                                            <BootstrapSwitchButton name="status" onChange={(e) => { updatestatus(e) }} onlabel='Active' offlabel='Inactive' width={125} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" onClick={save} >Save</Button>
                                <Link to="/Category">
                                    <Button variant="secondary">Cancel</Button>
                                </Link>
                            </Card.Footer>
                        </Form>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default ImagesAdd