import React from 'react'
import { Row, Col, Card, Form, Button, ButtonGroup, Breadcrumb } from 'react-bootstrap';
import Select from 'react-select';
import Layout from '../../layout/Layout';
import { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { API } from '../../App';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import Checkvalidator from '../Checkvalidator';


const NewsAdd = () => {
    const Redirect = useNavigate()

    const [getheadline, setheadline] = useState([])
    const [loading, Setloading] = useState(false)
    const [url, seturl] = useState("")
    const [name, setname] = useState("")
    const [Data, setData] = useState([])
    const [newsHeadline, setnewsHeadline] = useState("")
    const [errors, setErrors] = useState({})
    var news

    const addtage = (tag1, name) => {
        const tag = {
            Label: tag1,
            value: "",
            Url: "",
            tag: name
        }
        setData([...Data, tag])
    }

    const selectdata = (e) => {
        setnewsHeadline(e.target.value)
        if (!!errors[e.target.name]) setErrors({
            ...errors,
            [e.target.name]: null
        })
    }

    const save = async () => {

        if (Cookies.get('jwt')) {
            const jwt = Cookies.get('jwt')
            const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redirect('/')
            }
            else {
                news = []
                Data.map((val) => {
                    news.push(val.value)
                })

                const newErrors = Checkvalidator("", "", "",newsHeadline)
                if (Object.keys(newErrors).length > 0) {
                    Setloading(false)
                    setErrors(newErrors)
                    console.log(newErrors)
                }
                else {
                    if (news.length === 0) {
                        toast.error("News Field Is Required", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 1000,
                            closeOnClick: true
                        });
                    }
                    else {
                        Setloading(true)
                        const Form = new FormData()
                        Form.append("newsheadline", newsHeadline)
                        Form.append("news", news)

                        const Response = await API.post("/News", Form)
                        if (Response.data.response_code === 200) {
                            Setloading(false)
                            toast.success(Response.data.response_message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 1000,
                                closeOnClick: true
                            });
                            Redirect('/News')
                        }
                        else {
                            Setloading(false)
                            toast.error(Response.data.response_message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 1000,
                                closeOnClick: true,
                            });
                        }
                    }
                }
            }
        } else {
            Redirect('/')
        }
    }

    const Remove = (index) => {
        const data = [...Data]
        data.splice(index, 1)
        setData(data)
    }

    const inputdata = (e, index, tag) => {
        if (tag === 'li') {
            let data = [...Data];
            data[index]['value'] = `<ul><${tag}>${e.target.value}</${tag}></ul>`;
            setData(data)
        }
        else {
            if (tag === "img") {
                let data = [...Data];
                data[index]['value'] = `<${tag} ${(tag === 'img') ? `src="${e.target.value}"` : ""} ></${tag}>`
                setData(data)
            }
            else {
                let data = [...Data];
                data[index]['value'] = `<${tag}>${e.target.value}</${tag}>`
                setData(data)
            }

        }
    }

    const inputtaga = (e, index, tag) => {
        if (e.target.name === 'url') {
            seturl(e.target.value)
        }
        else {
            setname(e.target.value)
        }
        let data = [...Data];
        data[index]['value'] = `<${tag} ${(e.target.name === 'url') ? `href="${e.target.value}"` : `href="${url}"`}>${(e.target.name === 'name') ? `${e.target.value}` : name}</${tag}>`
        setData(data)
    }

    const getheadlinedata = async () => {
        const Response = await API.post('/GetTitle', {}, { headers: { "Authorization": `Bearer ${Cookies.get('jwt')}` } })
        if (Cookies.get('jwt')) {
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redirect("/")
            }
            setheadline(Response.data.data)
            console.log(Response.data.data);
        }
        else {
            Redirect("/")
        }

    }

    useEffect(() => {
        getheadlinedata()
    }, [])

    return (
        <>
            {(loading === true) ? <div className="loader" ></div> : <></>}
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3>News Add</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/News">News Lists</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create News</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className="page-content">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor='Headline-name'>News Headline</Form.Label>
                                        <Form.Select id="Headline-name" className="my-2" name='Headline' onChange={(e) => { selectdata(e) }} isInvalid={!!errors.Headline} required>
                                            <option value="">Select News Headline</option>
                                            {
                                                getheadline.map((val, index) => {
                                                    return (
                                                        <option value={val._id} key={index}>{val.title}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>{errors.Headline}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={12}>
                                    <ButtonGroup className="my-3 d-flex" >
                                        <Button onClick={() => { addtage('P Tag', 'p') }} >{`< P >`}</Button>
                                        <Button onClick={() => { addtage('Li Tag', 'li') }}>{`ul (li)`}</Button>
                                        <Button onClick={() => { addtage('IMG Tag', 'img') }}>{`Image`}</Button>
                                        <Button onClick={() => { addtage('H1 Tag', 'h1') }}>{`< h1 >`}</Button>
                                        <Button onClick={() => { addtage('H2 Tag', 'h2') }}>{`< h2 >`}</Button>
                                        <Button onClick={() => { addtage('H3 Tag', 'h3') }}>{`< h3 >`}</Button>
                                        <Button onClick={() => { addtage('H4 Tag', 'h4') }}>{`< h4 >`}</Button>
                                        <Button onClick={() => { addtage('SCRIPT Tag', 'Script') }}>{`Git Code`}</Button>
                                        <Button onClick={() => { addtage('a', 'a') }}>{`< a >`}</Button>
                                        <Button onClick={() => { addtage('Other Tag', 'p') }}>{`Other`}</Button>
                                    </ButtonGroup>
                                    <Form.Control.Feedback type='invalid'>{errors.news}</Form.Control.Feedback>
                                </Col>
                                {
                                    Data.map((val, index) => {
                                        return (

                                            (val.Label !== "a") ? (
                                                <Row className='d-flex align-items-center' key={index}>
                                                    <Col sm={11}>
                                                        <Form.Label htmlFor={`${val.Label}_name`} >{val.Label}</Form.Label>
                                                        <Form.Control id={`${val.Label}_name`} type="text" className="my-2" onChange={(e) => { inputdata(e, index, val.tag) }} />

                                                    </Col>
                                                    <Col sm={1} className="mt-3">
                                                        <Button className='btn btn-danger' onClick={() => { Remove(index) }} >Remove</Button>
                                                    </Col>
                                                </Row>) : (
                                                <Row className='d-flex align-items-center' key={index}>
                                                    <Col sm={6}>
                                                        <Form.Label htmlFor={`url_name`} >Url</Form.Label>
                                                        <Form.Control id={`url_name`} type="text" className="my-2" name="url" onChange={(e) => { inputtaga(e, index, val.tag) }} />
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Form.Label htmlFor={`name`} >Name</Form.Label>
                                                        <Form.Control id={`name`} type="text" className="my-2" name="name" onChange={(e) => { inputtaga(e, index, val.tag) }} />
                                                    </Col>
                                                    <Col sm={1} className="mt-3">
                                                        <Button className='btn btn-danger' onClick={() => { Remove(index) }} >Remove</Button>
                                                    </Col>
                                                </Row>
                                            )

                                        )
                                    })
                                }

                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" className="me-3" onClick={save} >Save</Button>
                            <Link to="/News">
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default NewsAdd