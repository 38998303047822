import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '@restart/ui/esm/Button'
import Dropdown from "react-bootstrap/Dropdown";
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from '../App';
import { useState } from 'react';
import DarkMode from "../Component/DarkMode";
import LogoMini from '../Component/Logo-mini';

const Header = ({ openSidebar }) => {

    const Redireact = useNavigate()
    const Logout = async () => {
        const Response = await API.post('/logout', {}, { headers: { "Authorization": `Bearer ${Cookies.get('jwt')}` } })
        if (Response.data.response_code === 200) {
            toast.success(Response.data.response_message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
                closeOnClick: true
            });
            Cookies.remove('jwt')
            Redireact('/')
        }
        else {
            toast.error(Response.data.response_message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
                closeOnClick: true,
            });
        }
    }

    return (
        <>
            <header className="sidebar-header">
                <div className="header-left-menu">
                    <Link to="/Category" className='d-xl-none'>
                        <LogoMini />
                    </Link>
                </div>
                <div className="header-right-menu">
                    <DarkMode />
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-autoclose-true">
                            <div className="user-menu">
                                <div className="user-img">
                                    <img src="../logo/app-icon.svg" alt="Financial Calculator" className='auth-logo' />
                                </div>
                                <div className="user-name ms-2">
                                    <h6>Financial Calculator</h6>
                                    <p>{Cookies.get('name')}</p>
                                </div>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                            <Dropdown.Item href="#" onClick={Logout} >Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button className="burger-btn d-xl-none" onClick={openSidebar}>
                        <i className='bx bx-menu fs-3'></i>
                    </Button>
                </div>
            </header>
        </>
    )
}

export default Header