import React from 'react'
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from '../../App';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { SelectPicker } from 'rsuite';


const Newsshow = () => {

    const { id } = useParams()
    const [Data, setData] = useState()
    const Redireact = useNavigate()
    const [title, settitle] = useState([])
    const [getid,setid] = useState({
        _id : id
      })

    const Getheadlinetitle = async () => {
        var title = []
        const Response = await API.post('/GetAllNews', {}, { headers: { "Authorization": `Bearer ${Cookies.get('jwt')}` } })
        Response.data.data.map((val, index) => {
            title.push({ label: (val.newsHeadlineId !== null) ? val.newsHeadlineId.title : "", value: val._id })
        })
        settitle(title)
      }

      const getinputname = (e) => {
        setid({_id:e})
        getdata(e)
      }

    const getdata = async (id) => {

        if (Cookies.get('jwt')) {
            const jwt = Cookies.get('jwt')
            const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redireact('/')
            }
            else {
                const Form = new FormData()
                Form.append('id', (id !== "") ? id : getid._id)
                const Response = await API.post('/EditNews', Form)
                setData(Response.data.data.news.replaceAll(",", ""))
            }
        } else {
            Redireact('/')
        }
    }

    useEffect(() => {
        getdata("")
        Getheadlinetitle()
    }, [])

    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/News" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link><span>View News Views</span></h3>
                <Row className="align-items-center">
                    <Col md="auto" xs={6}>
                        <SelectPicker data={title} defaultValue={getid._id} placeholder="Select Your Title" className="wv-225 ms-3 my-1" cleanable={false} onChange={(e) => { getinputname(e) }} />
                    </Col>
                    <Col md="auto" xs={6}>
                        <Link to={`/News/Edit/${getid._id}`}>
                            <Button variant="primary" value="edit">Edit</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                <div className="content" dangerouslySetInnerHTML={{__html: Data}}></div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default Newsshow