import React, { useState } from 'react'
import { Row, Col, Card, Form, InputGroup, Button, Breadcrumb } from 'react-bootstrap';
import Select from 'react-select';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout';
import { API } from '../../App';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Checkvalidator from '../Checkvalidator';

const ImagesAdd = () => {

    const Redireact = useNavigate()
    const [loading, Setloading] = useState(false)
    const [GetCategory, SetCategory] = useState([])
    const [errors, setErrors] = useState({})    
    const GetCategoryName = async () => {
        const Response = await API.post('/Allcategory', {}, { headers: { "Authorization": `Bearer ${Cookies.get('jwt')}` } })
        if (Cookies.get('jwt')) {
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redireact("/")
            }
            SetCategory(Response.data.data)
        }
        else {
            Redireact("/")
        }

    }

    const [Data, setData] = useState({
        newsCategory: "",
        title: "",
        description: "",
        news_url: "",
        headtag: "",
        date: "",
    })

    const [image, setimage] = useState({
        headline: "",
        website: ""
    })

    const [slider, setslider] = useState(0)
    const [status, setstatus] = useState(0)


    const inputdata = (e) => {
            setData({ ...Data, [e.target.name]: (e.target.name === "date") ? new Date(e.target.value) : e.target.value.trim() })
            if (!!errors[e.target.name]) setErrors({
                ...errors,
                [e.target.name]: null
            })
    }

    const inputfile = (e) => {
            setimage({ ...image, [e.target.name]: e.target.files[0] })
            if (!!errors[e.target.name]) setErrors({
                ...errors,
                [e.target.name]: null
            })
    }

    const inputslider = (e) => {
        var status = (e === true) ? 1 : 0
        setslider(status)
    }

    const inputstatus = (e) => {
        var status = (e === true) ? 1 : 0
        setstatus(status)
    }

    const save = async () => {
        if (Cookies.get('jwt')) {
            const jwt = Cookies.get('jwt')
            const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redireact('/')
            }
            else {
                const newErrors = Checkvalidator("",Data,image)
                if (Object.keys(newErrors).length > 0) {
                    Setloading(false)
                    setErrors(newErrors)
                }
                else {
                    Setloading(true)
                    const Form = new FormData()
                    Form.append("newsCategory", Data.newsCategory)
                    Form.append("title", Data.title.trim())
                    Form.append("description", Data.description)
                    Form.append("news_url", Data.news_url)
                    Form.append("headtag", Data.headtag)
                    Form.append("date", Data.date)
                    Form.append("headlineImage", image.headline)
                    Form.append("websiteImage", image.website)
                    Form.append("is_slider", slider)
                    Form.append("status", status)

                    const Response = await API.post('/NewsHeadline', Form)
                    if (Response.data.response_code === 200) {
                        Setloading(false)
                        toast.success(Response.data.response_message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 1000,
                            closeOnClick: true
                        });
                        Redireact('/Headlines')
                    }
                    else {
                        Setloading(false)
                        toast.error(Response.data.response_message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 1000,
                            closeOnClick: true,
                        });
                    }
                }
            }
        }
        else {
            Redireact('/')
        }
    }


    useEffect(() => {
        GetCategoryName()
    }, [])

    return (
        <>
            {(loading === true) ? <div className="loader" ></div> : <></>}
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3>News Headlines Add</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                        <Link to="/Headlines">News Headlines Lists</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create News Headlines</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className="page-content">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor='Category-name'>Category</Form.Label>
                                        <Form.Select id="Category-name" className="my-2" name='newsCategory' onChange={(e) => { inputdata(e) }} isInvalid={!!errors.newsCategory} required>
                                            <option value="">Select Category</option>
                                            {
                                                GetCategory.map((val, index) => {
                                                    return (
                                                        <option value={val._id} key={index}>{val.name}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>{errors.newsCategory}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_title">Title</Form.Label>
                                        <Form.Control id="hl_title" type="text" className="my-2" name="title" onChange={(e) => { inputdata(e) }} isInvalid={!!errors.title} required />
                                        <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_desc">Description</Form.Label>
                                        <Form.Control id="hl_desc" type="text" className="my-2" name="description" onChange={(e) => { inputdata(e) }} isInvalid={!!errors.description} required />
                                        <Form.Control.Feedback type='invalid'>{errors.description}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_url">News URL</Form.Label>
                                        <Form.Control id="hl_url" type="text" className="my-2" name="news_url" onChange={(e) => { inputdata(e) }} isInvalid={!!errors.news_url} required />
                                        <Form.Control.Feedback type='invalid'>{errors.news_url}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_head">Head : Note: Headtag Given By SEO</Form.Label>
                                        <Form.Control id="hl_head" as="textarea" rows={3} className="my-2" name="headtag" onChange={(e) => { inputdata(e) }} isInvalid={!!errors.headtag} required />
                                        <Form.Control.Feedback type='invalid'>{errors.headtag}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={3}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_date">Date</Form.Label>
                                        <Form.Control id="hl_date" type="date" className="my-2" name="date" onChange={(e) => { inputdata(e) }} isInvalid={!!errors.date} required />
                                        <Form.Control.Feedback type='invalid'>{errors.date}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={3}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_image">Image</Form.Label>
                                        <Form.Control id="hl_image" type="file" className="my-2" name="headline" onChange={(e) => { inputfile(e) }} isInvalid={!!errors.headline} required />
                                        <Form.Control.Feedback type='invalid'>{errors.headline}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={3}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_web">Website Image</Form.Label>
                                        <Form.Control id="hl_web" type="file" className="my-2" name="website" onChange={(e) => { inputfile(e) }} isInvalid={!!errors.website} required />
                                        <Form.Control.Feedback type='invalid'>{errors.website}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group className="form-group">
                                        <Form.Label className="d-block mb-2">Is Slider</Form.Label>
                                        <BootstrapSwitchButton onlabel='Active' offlabel='Deactive' onChange={(e) => { inputslider(e) }} width={125} />
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group className="form-group">
                                        <Form.Label className="d-block mb-2">Status</Form.Label>
                                        <BootstrapSwitchButton onlabel='Active' offlabel='Deactive' onChange={(e) => { inputstatus(e) }} width={125} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" className="me-3" onClick={save}>Save</Button>
                            <Link to="/Headlines">
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default ImagesAdd