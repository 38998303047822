import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Table, Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout';
import { useState } from 'react';
import { API } from '../../App';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from 'rc-pagination'
import Cookies from 'js-cookie';
import MDEditor from '@uiw/react-md-editor';
import Fancybox from '../../Component/FancyBox';
import $ from 'jquery'
import Swal from 'sweetalert2';
import { SelectPicker } from 'rsuite';
import DateRangePicker from 'react-bootstrap-daterangepicker';

var id = []
const NewsCategory = () => {

  const Redirect = useNavigate()
  const [Data, setData] = useState([])
  const [loading, Setloading] = useState(true)
  const [Searchtitle, Setsearchtitle] = useState()
  const [status, Setstatus] = useState()
  const [iconcoror, Seticoncoror] = useState("created_at_up")
  const [searchdata, setsearchdata] = useState({
    title: "",
    status: "",
    date: "",
  })


  const Getheadlinetitle = async () => {
    var title = []
    const Form = new FormData()
    Form.append("title", "all")
    const Response = await API.post('/GetTitle', Form, { headers: { "Authorization": `Bearer ${Cookies.get('jwt')}` } })
    title.push({ label: "All Title", value: "" })
    Response.data.data.map((val, index) => {
      title.push({ label: val.title, value: val._id })
    })
    Setsearchtitle(title)
  }

  const getData = async () => {
    const Response = await API.post('/GetAllHeadline', {}, { headers: { "Authorization": `Bearer ${Cookies.get('jwt')}` } })
    if (Cookies.get('jwt')) {
      if (Response.data.response_message === "invalid token") {
        Cookies.remove('jwt')
        Redirect("/")
      }
      if (Response.data.data.length === 0) {
        $('#remove_tr').empty()
        $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found"><img src="../../not-found/rds.svg"/><p>No Found Headlines</p></div></td>')
        setData(Response.data.data)
        Setloading(false)
      }
      else {
        id = []
        Response.data.data.map((val, index) => {
          id.push(val._id)
        })
        Setstatus([])
        Setstatus([{ label: "All Status", value: "" }, { label: "Active", value: "1" }, { label: "Deactive", value: "0" }])
        $('#remove_tr').empty()
        setData(Response.data.data)
        Setloading(false)
      }

    }
    else {
      Redirect("/")
    }
  }

  const searchtitle = async (e, name) => {
    if (name === "cancle") {
      setsearchdata({ ...searchdata, ['date']: "" })
    }
    else {
      setsearchdata({ ...searchdata, [name]: (name === "date") ? e.target.value : e })
    }

    const Form = new FormData()
    Form.append('title', (name === "title") ? e : searchdata.title)
    Form.append('status', (name === "status") ? e : searchdata.status)
    Form.append('date', (name === "cancle") ? "" : (name === "date") ? e.target.value : searchdata.date)

    const Response = await API.post('/SearchTitle', Form)

    if (Response.data.data.length === 0) {
      $('#remove_tr').empty()
      $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found"><img src="../../not-found/rds.svg"/><p>No Found Headlines</p></div></td>')
      setData(Response.data.data)
    }
    else {
      $('#remove_tr').empty()
      setData(Response.data.data)
      setCurrent(1)
    }
  }

  const updatestatus = async (e, id) => {
    const Form = new FormData()
    Form.append('id', id)
    Form.append('status', (e === true) ? 1 : 0)
    const Response = await API.post('/StatusupdateHeadline', Form)
    if (Response.data.response_code === 200) {
      toast.success(Response.data.response_message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
        closeOnClick: true
      });
      getData()
    }
    else {
      toast.error(Response.data.response_message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
        closeOnClick: true,
      });
    }
  }

  const sorting = (col, type = "string", order, e) => {
    Seticoncoror(e.target.id)
    if (order === "ASC") {
      if (col === "newsCategoryId") {
        const sorted = [...Data].sort((a, b) =>
          a.newsCategoryId.name.toLowerCase().trim() > b.newsCategoryId.name.toLowerCase().trim() ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setData(sorted)
        }
      }
      else if (col === "status") {
        const sorted = [...Data].sort((a, b) =>
          parseInt(a[col]) > parseInt(b[col]) ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setData(sorted)
        }
      }
      else if (col === "title") {
        const sorted = [...Data].sort((a, b) =>
          a[col].toLowerCase().trim() > b[col].toLowerCase().trim() ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setData(sorted)
        }
      }
      else {
        const sorted = [...Data].sort((a, b) =>
          a[col] > b[col] ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setData(sorted)
        }
      }

    }
    if (order === "DSC") {
      if (col === "newsCategoryId") {
        const sorted = [...Data].sort((a, b) =>
          a.newsCategoryId.name.toLowerCase().trim() < b.newsCategoryId.name.toLowerCase().trim() ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setData(sorted)
        }
      }
      else if (col === "status") {
        const sorted = [...Data].sort((a, b) =>
          parseInt(a[col]) < parseInt(b[col]) ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setData(sorted)
        }
      }
      else if (col === "title") {
        const sorted = [...Data].sort((a, b) =>
          a[col].toLowerCase().trim() < b[col].toLowerCase().trim() ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setData(sorted)
        }
      }
      else {
        const sorted = [...Data].sort((a, b) =>
          a[col] < b[col] ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          setData(sorted)
        }
      }
    }
  }

  const changedateformate = (d) => {
    const monthname = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const date = new Date(d)
    const month = monthname[date.getMonth('M')]
    const years = date.getFullYear()
    const day = date.getDate()
    var dateformte = day + " " + month + "," + " " + years
    return dateformte;
  }

  const deletedata = async (id) => {
    if (Cookies.get('jwt')) {
      const jwt = Cookies.get('jwt')
      const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
      if (Response.data.response_message === "invalid token") {
        Cookies.remove('jwt')
        Redirect('/')
      }
      else {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger me-2",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel! ",
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const Form = new FormData()
              Form.append('id', id)
              await API.post('/DeleteHeadline', Form)
              getData()

            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
          });
      }
    } else {
      Redirect('/')
    }
  }

  useEffect(() => {
    getData()
    Getheadlinetitle()
  }, [])

  // Paggintion Code // 
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);

  const getData1 = (current, pageSize) => {
    return Data.slice((current - 1) * pageSize, current * pageSize);
  };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(Data.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  }

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize)
  }


  const PrevNextArrow = (current, type, originalElement) => {
    if (type === 'prev') {
      return <button className='paggination-btn'>Previous</button>;
    }
    if (type === 'next') {
      return <button className='paggination-btn'>Next</button>;
    }
    return originalElement;
  }

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>News Headlines</h3>
        <div className="page-heading-right">
          <SelectPicker data={Searchtitle} defaultValue={""} placeholder="Select Your Title" className="wv-225 ms-3 my-1" cleanable={false} onChange={(e) => { searchtitle(e, "title") }} />
          <SelectPicker data={status} defaultValue={""} className="wv-225 ms-3 my-1" cleanable={false} onChange={(e) => { searchtitle(e, "status") }} />
          <DateRangePicker className="wv-225 ms-3 my-1" onApply={(e) => { searchtitle(e, 'date') }} onCancel={(e) => { searchtitle(e, 'cancle') }}>
            <input type="text" name="date" placeholder=" Select Date" value={searchdata.date} className="form-control wv-225 ms-3 my-1" autoComplete='off' />
          </DateRangePicker>
          <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="form-control wv-100 ms-3 my-1">
            {
              [10, 20, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))
            }
          </Form.Select>
          <Link className="btn btn-primary ms-3 my-1" to="/Headlines/Add">Add New</Link>
        </div>
      </div>

      <div className="page-content">
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th width="5%">
                        <div className='table-sort-filter justify-content-center'>No.
                        </div>
                      </th>
                      <th width="10%">
                        <div className='table-sort-filter justify-content-center'>Category Name
                          <span className='table-sort'>
                            <div className={`sort-down ${iconcoror === "newsCategoryId_down" ? "active" : ""}`} id="newsCategoryId_down" onClick={(e) => { sorting('newsCategoryId', "", "DSC", e) }} ></div>
                            <div className={`sort-up ${iconcoror === "newsCategoryId_up" ? "active" : ""}`} id="newsCategoryId_up" onClick={(e) => { sorting('newsCategoryId', "", "ASC", e) }} ></div>
                          </span>
                        </div>
                      </th>
                      <th width="20%">
                        <div className='table-sort-filter'>Title
                          <span className='table-sort'>
                            <div className={`sort-down ${iconcoror === "title_down" ? "active" : ""}`} id="title_down" onClick={(e) => { sorting('title', "", "DSC", e) }} ></div>
                            <div className={`sort-up ${iconcoror === "title_up" ? "active" : ""}`} id="title_up" onClick={(e) => { sorting('title', "", "ASC", e) }} ></div>
                          </span>
                        </div>
                      </th>
                      <th width="20%">Description</th>
                      <th width="6%" className='text-center'>News Url</th>
                      <th width="8%">
                        <div className='table-sort-filter justify-content-center'>Date
                          <span className='table-sort'>
                            <div className={`sort-down ${iconcoror === "date_down" ? "active" : ""}`} id="date_down" onClick={(e) => { sorting('date', "", "DSC", e) }} ></div>
                            <div className={`sort-up ${iconcoror === "date_up" ? "active" : ""}`} id="date_up" onClick={(e) => { sorting('date', "", "ASC", e) }} ></div>
                          </span>
                        </div>
                      </th>
                      <th width="8%" className='text-center'>Image</th>
                      <th width="7%">Head Tag</th>
                      <th width="8%">
                        <div className='table-sort-filter justify-content-center'>Status
                          <span className='table-sort'>
                            <div className={`sort-down ${iconcoror === "status_down" ? "active" : ""}`} id="status_down" onClick={(e) => { sorting('status', "", "DSC", e) }} ></div>
                            <div className={`sort-up ${iconcoror === "status_up" ? "active" : ""}`} id="status_up" onClick={(e) => { sorting('status', "", "ASC", e) }} ></div>
                          </span>
                        </div>
                      </th>
                      <th width="8%" className='text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getData1(current, size).map((val, index) => {
                      return (
                        <tr key={index}>
                          <td className='text-center'>{id.indexOf(val._id) + 1}</td>
                          <td>{(val.newsCategoryId !== null) ? val.newsCategoryId.name : "-"}</td>
                          <td><div className='dot-line-2'>{val.title}</div></td>
                          <td><div className='dot-line-2'>{val.description}</div></td>
                          <td className='text-center'>
                            <OverlayTrigger overlay={<Tooltip> {val.news_url} </Tooltip>}>
                              <a href={val.news_url} target="_blank" className="btn btn-outline-primary me-2 btn-icon btn-sm" ><i className='bx bx-link-external'></i></a>
                            </OverlayTrigger>
                          </td>
                          <td className='text-center'>{changedateformate(val.date)}</td>
                          <td className='text-center'>
                            <Fancybox>
                              <a data-fancybox="gallery" href={val.headlineImage}>
                                <img src={val.headlineImage} className="hv-30" alt="avatar" />
                              </a>
                            </Fancybox>
                          </td>
                          <td>{val.headtag}</td>
                          <td className='text-center'><BootstrapSwitchButton checked={(val.status === 1) ? true : false} onlabel='Active' onChange={(e) => { updatestatus(e, val._id) }} offlabel='Deactive' size="sm" width={100} /></td>
                          <td className='text-center'>
                            <Link to={`/Headlines/View/${val._id}`}>
                              <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                            </Link>
                            <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => { deletedata(val._id) }}><i className='bx bx-trash-alt' ></i></Button>
                          </td>
                        </tr>

                      )
                    })
                    }
                    <tr id="remove_tr">

                    </tr>
                  </tbody>
                </Table>
                {(Data.length > size) ?
                  <div className="pagination-custom">
                    <Pagination showTitle={false}
                      className="pagination-data"
                      onChange={PaginationChange}
                      total={Data.length}
                      current={current}
                      pageSize={size}
                      showSizeChanger={false}
                      itemRender={PrevNextArrow}
                      onShowSizeChange={PerPageChange}
                    />
                  </div> : ""}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default NewsCategory