import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Table, Card } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import { API } from '../../App';
import { useState } from 'react';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from 'rc-pagination'
import Cookies from 'js-cookie';
import Fancybox from '../../Component/FancyBox';
import $ from 'jquery'
import Swal from 'sweetalert2';

var id = []
const NewsCategory = () => {

  const [Data, SetData] = useState([])
  const Redirect = useNavigate()
  const [iconcoror, Seticoncoror] = useState("created_at_up")
  const [loading, Setloading] = useState(true)

  const getdata = async () => {
    const Response = await API.post('/GetAllNewsimage', {}, { headers: { "Authorization": `Bearer ${Cookies.get('jwt')}` } })
    if (Cookies.get('jwt')) {
      if (Response.data.response_message === "invalid token") {
        Cookies.remove('jwt')
        Redirect("/")
      }
      if (Response.data.data.length === 0) {
        $('#remove_tr').empty()
        $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found"><img src="../../not-found/rds.svg"/><p>No Found Image</p></div></td>')
        SetData(Response.data.data)
        Setloading(false)
      }
      else {
        id = []
        Response.data.data.map((val, index) => {
          id.push(val._id)
        })
        $('#remove_tr').empty()
        SetData(Response.data.data)
        Setloading(false)
      }
    }
    else {
      Redirect("/")
    }
  }

  const sorting = (col, type = "string", order, e) => {
    Seticoncoror(e.target.id)
    if (order === "ASC") {
      const sorted = [...Data].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      if (iconcoror !== e.target.id) {
        SetData(sorted)
      }
    }
    if (order === "DSC") {

      const sorted = [...Data].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      if (iconcoror !== e.target.id) {
        SetData(sorted)
      }
    }
  }

  const deletedata = async (id) => {

    if (Cookies.get('jwt')) {
      const jwt = Cookies.get('jwt')
      const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
      if (Response.data.response_message === "invalid token") {
        Cookies.remove('jwt')
        Redirect('/')
      }
      else {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger me-2",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel! ",
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const Form = new FormData()
              Form.append('id', id)
              await API.post('/DeletedNewsimage', Form)
              getdata()

            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
          });
      }
    } else {
      Redirect('/')
    }
  }

  useEffect(() => {
    getdata()
  }, [])

  // Paggintion Code // 
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);

  const getData1 = (current, pageSize) => {
    return Data.slice((current - 1) * pageSize, current * pageSize);
  };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(Data.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  }

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize)
  }


  const PrevNextArrow = (current, type, originalElement) => {
    if (type === 'prev') {
      return <button className='paggination-btn'>Previous</button>;
    }
    if (type === 'next') {
      return <button className='paggination-btn'>Next</button>;
    }
    return originalElement;
  }

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3><Link to="/News" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link><span>News Image</span></h3>
        <Row className="align-items-center">
          <Col lg="auto">
            <Link className="btn btn-primary" to="/News/Image/Add">Add New</Link>
          </Col>
        </Row>
      </div>

      <div className="page-content">
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                <Table bordered responsive id="myTable">
                  <thead>
                    <tr>
                      <th width="5%" className='text-center'>
                        <div className='table-sort-filter justify-content-center'>No.
                        </div>
                      </th>
                      <th width="75%">Link</th>
                      <th width="10%" className='text-center'>Image</th>
                      <th width="10%" className='text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      getData1(current, size).map((val, index) => {
                        return (
                          <tr key={index}>
                            <td className='text-center'>{id.indexOf(val._id) + 1}</td>
                            <td>{val.newsimage}</td>
                            <td className='text-center'>
                              <Fancybox>
                                <a data-fancybox="gallery" href={val.newsimage}>
                                  <img src={val.newsimage} className="hv-30" alt="Image" />
                                </a>
                              </Fancybox>
                            </td>
                            <td className='text-center'>
                              <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => { deletedata(val._id) }}><i className='bx bx-trash-alt' ></i></Button>
                            </td>
                          </tr>
                        )
                      })
                    }
                    <tr id="remove_tr">

                    </tr>
                  </tbody>
                </Table>
                {(Data.length > size) ?
                  <div className="pagination-custom">
                    <Pagination showTitle={false}
                      className="pagination-data"
                      onChange={PaginationChange}
                      total={Data.length}
                      current={current}
                      pageSize={size}
                      showSizeChanger={false}
                      itemRender={PrevNextArrow}
                      onShowSizeChange={PerPageChange}
                    />
                  </div> : ""}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default NewsCategory