import React, { useState } from 'react'
import { Row, Col, Card, Form, InputGroup, Button, Breadcrumb } from 'react-bootstrap';
import Select from 'react-select';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout';
import { API } from '../../App';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import Checkvalidator from '../Checkvalidator';

const HeadlinesEdit = () => {
    const Redireact = useNavigate()
    const { id } = useParams()
    const [loading, Setloading] = useState(false)
    const [GetCategory, SetCategory] = useState([])
    const [errors, setErrors] = useState({})

    const [Data, setData] = useState({
        newsCategory: "",
        title: "",
        description: "",
        news_url: "",
        headtag: "",
        date: "",
        headlineImage: "",
        websiteImage: "",
        is_slider: "",
        status: ""
    })

    const GetCategoryName = async () => {
        const Response = await API.post('/Allcategory', {}, { headers: { "Authorization": `Bearer ${Cookies.get('jwt')}` } })
        if (Cookies.get('jwt')) {
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redireact("/")
            }
            SetCategory(Response.data.data)
        }
        else {
            Redireact("/")
        }
    }

    const getAlldata = async () => {
        const Form = new FormData()
        Form.append('id', id)
        const Response = await API.post('/EditHeadline', Form)
        setData({
            newsCategory: (Response.data.data.newsCategoryId !== null) ? Response.data.data.newsCategoryId._id : "",
            title: Response.data.data.title,
            description: Response.data.data.description,
            news_url: Response.data.data.news_url,
            headtag: Response.data.data.headtag,
            date: Response.data.data.date,
            headlineImage: Response.data.data.headlineImage,
            websiteImage: Response.data.data.websiteImage,
            is_slider: Response.data.data.is_slider,
            status: Response.data.data.status
        })
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const inputdata = async (e) => {
        setData({ ...Data, [e.target.name]: (e.target.name === "date") ? e.target.value : e.target.value })
        if (!!errors[e.target.name]) setErrors({
            ...errors,
            [e.target.name]: null
        })
    }

    const inputfile = async (e) => {
        setData({ ...Data, [e.target.name]: e.target.files[0] })
        if (!!errors[e.target.name]) setErrors({
            ...errors,
            [e.target.name]: null
        })
    }

    const inputslider = (e) => {
        var status = (e === true) ? 1 : 0
        setData({ ...Data, ['is_slider']: status })
    }

    const inputstatus = (e) => {
        var status = (e === true) ? 1 : 0
        setData({ ...Data, ['status']: status })
    }

    const save = async () => {

        if (Cookies.get('jwt')) {
            const jwt = Cookies.get('jwt')
            const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redireact('/')
            }
            else {
                const newErrors = Checkvalidator("",Data,"")
                if (Object.keys(newErrors).length > 0) {
                    Setloading(false)
                    setErrors(newErrors)
                    console.log(newErrors)
                }
                else {
                    Setloading(true)
                    const Form = new FormData()
                    Form.append("newsCategory", Data.newsCategory)
                    Form.append("title", Data.title.trim())
                    Form.append("description", Data.description)
                    Form.append("news_url", Data.news_url)
                    Form.append("headtag", Data.headtag)
                    Form.append("date", Data.date)
                    Form.append("headlineImage", Data.headlineImage)
                    Form.append("websiteImage", Data.websiteImage)
                    Form.append("is_slider", Data.is_slider)
                    Form.append("status", Data.status)
                    Form.append("id", id)

                    const Response = await API.post('/UpdateHeadline', Form)
                    if (Response.data.response_code === 200) {
                        Setloading(false)
                        toast.success(Response.data.response_message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 1000,
                            closeOnClick: true
                        });
                        Redireact(`/Headlines/View/${id}`)
                    }
                    else {
                        Setloading(false)
                        toast.error(Response.data.response_message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 1000,
                            closeOnClick: true,
                        });
                    }
                }
            }
        } else {
            Redireact('/')
        }

    }


    useEffect(() => {
        GetCategoryName()
        getAlldata()
    }, [])
    return (
        <>
            {(loading === true) ? <div className="loader" ></div> : <></>}

            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3>News Headlines Edit</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/Headlines">News Headlines Lists</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Edit News Headlines</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className="page-content">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group className="form-group">
                                        {console.log(Data.date)}
                                        <Form.Label htmlFor='Category-name'>Category</Form.Label>
                                        <Form.Select id="Category-name" className="my-2" name='newsCategory' onChange={(e) => { inputdata(e) }} isInvalid={!!errors.newsCategory} required>
                                            <option value="">Select Category</option>
                                            {
                                                GetCategory.map((val, index) => {
                                                    return (
                                                        <option value={val._id} selected={(Data.newsCategory !== "") ? Data.newsCategory === val._id ? true : false : false} key={index}>{val.name}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>{errors.newsCategory}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_title">Title</Form.Label>
                                        <Form.Control id="hl_title" type="text" value={Data.title} className="my-2" onChange={(e) => { inputdata(e) }} name="title" isInvalid={!!errors.title} required />
                                        <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_desc">Description</Form.Label>
                                        <Form.Control id="hl_desc" type="text" className="my-2" value={Data.description} onChange={(e) => { inputdata(e) }} name="description" isInvalid={!!errors.description} required />
                                        <Form.Control.Feedback type='invalid'>{errors.description}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_url">News URL</Form.Label>
                                        <Form.Control id="hl_url" type="text" className="my-2" value={Data.news_url} onChange={(e) => { inputdata(e) }} name="news_url" isInvalid={!!errors.news_url} required />
                                        <Form.Control.Feedback type='invalid'>{errors.news_url}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_head">Head : Note: Headtag Given By SEO</Form.Label>
                                        <Form.Control id="hl_head" as="textarea" rows={3} className="my-2" value={Data.headtag} onChange={(e) => { inputdata(e) }} name="headtag" isInvalid={!!errors.headtag} required />
                                        <Form.Control.Feedback type='invalid'>{errors.headtag}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={3}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="hl_date">Date</Form.Label>
                                        <Form.Control id="hl_date" type="date" className="my-2" value={formatDate(Data.date)} onChange={(e) => { inputdata(e) }} name="date" isInvalid={!!errors.date} required />
                                        <Form.Control.Feedback type='invalid'>{errors.date}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm={3}>
                                    <Form.Label htmlFor="hl_image">Image</Form.Label>
                                    <Form.Group className="form-group">
                                        <InputGroup className="my-2">
                                            <Form.Control id="hl_image" type="file" className="" name="headlineImage" onChange={(e) => { inputfile(e) }} isInvalid={!!errors.headlineImage} required />
                                            <img src={Data.headlineImage} height="40" alt='' />
                                            <Form.Control.Feedback type='invalid'>{errors.headlineImage}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>

                                <Col sm={3}>
                                    <Form.Label htmlFor="hl_web">Website Image</Form.Label>
                                    <Form.Group className="form-group">
                                        <InputGroup className="my-2">
                                            <Form.Control id="hl_web" type="file" className="" name="websiteImage" onChange={(e) => { inputfile(e) }} isInvalid={!!errors.websiteImage} required />
                                            <img src={Data.websiteImage} height="40" alt='' />
                                            <Form.Control.Feedback type='invalid'>{errors.websiteImage}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group className="form-group">
                                        <Form.Label className="d-block mb-2">Is Slider</Form.Label>
                                        <BootstrapSwitchButton checked={(Data.is_slider === 1) ? true : false} onChange={(e) => { inputslider(e) }} onlabel='Active' offlabel='Deactive' width={125} />
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group className="form-group">
                                        <Form.Label className="d-block mb-2">Status</Form.Label>
                                        <BootstrapSwitchButton checked={(Data.status === 1) ? true : false} onChange={(e) => { inputstatus(e) }} onlabel='Active' offlabel='Deactive' width={125} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" className="me-3" onClick={save}>Save</Button>
                            <Link to={`/Headlines/View/${id}`}>
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default HeadlinesEdit