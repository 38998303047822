import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../../layout/Layout'
import Fancybox from '../../Component/FancyBox';
import Cookies from 'js-cookie';
import { API } from '../../App';
import { SelectPicker } from 'rsuite';


const HeadlinesView = () => {
    const { id } = useParams()
    const [Data, setData] = useState([])
    const [catename, setcatename] = useState("")
    const [title, settitle] = useState([])
    const Redireact = useNavigate()
    const [getid, setid] = useState({
        _id: id
    })

    const Getheadlinetitle = async () => {
        var title = []
        const Form = new FormData()
        Form.append("title", "all")
        const Response = await API.post('/GetTitle', Form, { headers: { "Authorization": `Bearer ${Cookies.get('jwt')}` } })
        Response.data.data.map((val, index) => {
            title.push({ label: val.title, value: val._id })
        })
        settitle(title)
    }

    const getinputname = (e) => {
        setid({ _id: e })
        getdata(e)
    }

    const changedateformate = (d) => {
        const monthname = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const date = new Date(d)
        const month = monthname[date.getMonth('M')]
        const years = date.getFullYear()
        const day = date.getDate()
        var dateformte = day + " " + month + "," + " " + years
        return dateformte;
    }

    const getdata = async (id) => {
        if (Cookies.get('jwt')) {
            const jwt = Cookies.get('jwt')
            const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redireact('/')
            }
            else {
                const Form = new FormData()
                Form.append('id', (id !== "") ? id : getid._id)
                const Response = await API.post('/ViewNewsHeadline', Form)
                setData(Response.data.data)
                if (Response.data.data.newsCategoryId !== null) {
                    setcatename(Response.data.data.newsCategoryId.name)
                }
            }
        } else {
            Redireact('/')
        }
    }

    useEffect(() => {
        getdata("")
        Getheadlinetitle()
    }, [])


    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/Headlines" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link><span>View News Headlines</span></h3>
                <div className="page-heading-right">
                    <SelectPicker data={title} defaultValue={getid._id} placeholder="Select Your Title" className="wv-225 ms-3 my-1" cleanable={false} onChange={(e) => { getinputname(e) }} />
                    <Link to={`/Headlines/Edit/${getid._id}`}>
                        <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
                    </Link>
                </div>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Cat. Name</p>  <span>{catename}</span>
                                </div>
                            </Col>
                            
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Date</p>  <span>{changedateformate(Data.date)}</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Headline Image</p>
                                    <Fancybox>
                                        <a data-fancybox="gallery" href={Data.headlineImage}>
                                            <img src={Data.headlineImage} className='hv-40 rounded-3' alt="avatar" />
                                        </a>
                                    </Fancybox>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Website Image</p>
                                    <Fancybox>
                                        <a data-fancybox="gallery" href={Data.websiteImage}>
                                            <img src={Data.websiteImage} className='hv-40 rounded-3' alt="avatar" />
                                        </a>
                                    </Fancybox>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Title</p>  <span>{Data.title}</span>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Description</p>  <span>{Data.description}</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Head Tag</p>  <span>{Data.headtag}</span>
                                </div>
                            </Col>

                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>News Url</p>  <span><OverlayTrigger overlay={<Tooltip> {Data.news_url} </Tooltip>}>
                                        <a href={Data.news_url} target="_blank" className="btn btn-outline-primary me-2 btn-icon btn-sm" ><i className='bx bx-link-external'></i></a>
                                    </OverlayTrigger> </span>
                                </div>
                            </Col>

                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Status</p>  <span>{(Data.status === 1) ? "Active" : "Deactive"}</span>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default HeadlinesView