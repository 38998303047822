import NewsView from './pages/NewsView';
import 'rsuite/dist/rsuite.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'boxicons/css/boxicons.css'
import 'bootstrap-daterangepicker/daterangepicker.css';
import './App.css';
import './utilities.css';
import { Routes, Route,HashRouter, BrowserRouter } from 'react-router-dom';
import Login from './pages/Login';
import Category from './pages/category/Category';
import CategoryAdd from './pages/category/CategoryAdd';
import Headlines from './pages/headlines/Headlines';
import HeadlinesAdd from './pages/headlines/HeadlinesAdd';
import News from './pages/news/News';
import NewsAdd from './pages/news/NewsAdd';
import NewsImage from './pages/news/NewsImage';
import NewsImageAdd from './pages/news/NewsImageAdd';

import axios from 'axios';
import CategoryEdit from './pages/category/CategoryEdit';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Newsshow from './pages/news/Newsshow';
import NewsEdit from './pages/news/NewsEdit';
import HeadlinesEdit from './pages/headlines/HeadlinesEdit';
import Page_not_found from './pages/Page_not_found';
import CategoryView from './pages/category/CategoryView';
import HeadlinesView from './pages/headlines/HeadlinesView';
// export const API = axios.create({baseURL:"https://financecalculator.vasundharaapps.com/"})    
export const API = axios.create({baseURL: process.env.REACT_APP_Api_link})
// export const API = axios.create({baseURL:"http://localhost:9686/"})

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          {/* <Route path="/Home" element={<Home/>} /> */}
          <Route path="/NewsView/:title" element={<NewsView/>} />
          <Route path="/Category" element={<Category/>} />
          <Route path="/Category/Add" element={<CategoryAdd/>} />
          <Route path="/Category/Edit/:id" element={<CategoryEdit/>} />
          <Route path="/Category/View/:id" element={<CategoryView/>} />

          <Route path="/Headlines" element={<Headlines/>} />
          <Route path="/Headlines/Add" element={<HeadlinesAdd/>} />
          <Route path="/Headlines/Edit/:id" element={<HeadlinesEdit/>} />
          <Route path="/Headlines/View/:id" element={<HeadlinesView/>} />

          <Route path="/News" element={<News/>} />
          <Route path="/News/Add" element={<NewsAdd/>} />
          <Route path="/News/Edit/:id" element={<NewsEdit/>} />
          <Route path="/News/View/:id" element={<Newsshow/>} />
          <Route path="/News/Image" element={<NewsImage/>} />
          <Route path="/News/Image/Add" element={<NewsImageAdd/>} />
          <Route path="/" element={<Login/>} />
          <Route path='*' element={<Page_not_found/>} />
        </Routes>
      </HashRouter>
      <ToastContainer/>
    </>
  )
}

export default App;
