import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Table, Card, Form } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import { useState } from 'react';
import { API } from '../../App';
import Pagination from 'rc-pagination'
import Cookies from 'js-cookie';
import $ from 'jquery'
import Swal from 'sweetalert2';
import { SelectPicker } from 'rsuite';

var id = []
const NewsCategory = () => {

  const Redirect = useNavigate()
  const [Data, Setdata] = useState([])
  const [loading, Setloading] = useState(true)
  const [searchname, Setsearchname] = useState()
  const [iconcoror, Seticoncoror] = useState("created_at_up")


  const getdata = async () => {
    const Response = await API.post('/GetAllNews', {}, { headers: { "Authorization": `Bearer ${Cookies.get('jwt')}` } })
    if (Cookies.get('jwt')) {
      if (Response.data.response_message === "invalid token") {
        Cookies.remove('jwt')
        Redirect("/")
      }
      if (Response.data.data.length === 0) {
        console.log("hello")
        $('#remove_tr').empty()
        $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found"><img src="../../not-found/rds.svg"/><p>No Found News</p></div></td>')
        Setdata(Response.data.data)
        Setloading(false)
      }
      else {
        var name = []
        name.push({ label: "All Name", value: "" })
        Response.data.data.map((val, index) => {
          name.push({ label: (val.newsHeadlineId !== null) ? val.newsHeadlineId.title : "", value: (val.newsHeadlineId !== null) ? val.newsHeadlineId._id : "" })
        })
        id = []
        Response.data.data.map((val, index) => {
          id.push(val._id)
        })
        $('#remove_tr').empty()
        Setdata(Response.data.data)
        Setloading(false)
        Setsearchname(name)
      }
    }
    else {
      Redirect("/")
    }
  }

  const searchtitle = async (e) => {
    const Form = new FormData()
    Form.append('id', e)
    const Response = await API.post('/Searchnews', Form)

    if (Response.data.data.length === 0) {
      $('#remove_tr').empty()
      $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found"><img src="../../not-found/rds.svg"/><p>No Found News</p></div></td>')
      Setdata(Response.data.data)
    }
    else {
      $('#remove_tr').empty()
      Setdata(Response.data.data)
      setCurrent(1)
    }
  }

  const sorting = (col, type = "string", order, e) => {
    Seticoncoror(e.target.id)
    if (order === "ASC") {
      if (col === "newsHeadlineId") {
        const sorted = [...Data].sort((a, b) =>
          a.newsHeadlineId.title.toLowerCase().trim() > b.newsHeadlineId.title.toLowerCase().trim() ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          Setdata(sorted)
        }
      }
      else {
        const sorted = [...Data].sort((a, b) =>
          a[col] > b[col] ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          Setdata(sorted)
        }
      }

    }
    if (order === "DSC") {
      if (col === "newsHeadlineId") {
        const sorted = [...Data].sort((a, b) =>
          a.newsHeadlineId.title.toLowerCase().trim() < b.newsHeadlineId.title.toLowerCase().trim() ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          Setdata(sorted)
        }
      } else {
        const sorted = [...Data].sort((a, b) =>
          a[col] < b[col] ? 1 : -1
        );
        if (iconcoror !== e.target.id) {
          Setdata(sorted)
        }
      }

    }
  }

  const deletedata = async (id) => {

    if (Cookies.get('jwt')) {
      const jwt = Cookies.get('jwt')
      const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
      if (Response.data.response_message === "invalid token") {
        Cookies.remove('jwt')
        Redirect('/')
      }
      else {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger me-2",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel! ",
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const Form = new FormData()
              Form.append('id', id)
              await API.post('/DeletedNews', Form)
              getdata()

            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
          });
      }
    } else {
      Redirect('/')
    }
  }

  useState(() => {
    getdata()
  }, [])

  // Paggintion Code // 
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);

  const getData1 = (current, pageSize) => {
    return Data.slice((current - 1) * pageSize, current * pageSize);
  };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(Data.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  }

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize)
  }


  const PrevNextArrow = (current, type, originalElement) => {
    if (type === 'prev') {
      return <button className='paggination-btn'>Previous</button>;
    }
    if (type === 'next') {
      return <button className='paggination-btn'>Next</button>;
    }
    return originalElement;
  }

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>News</h3>
        <div className="page-heading-right">
          <SelectPicker data={searchname} defaultValue={""} placeholder="Select Your Name" className="wv-225 ms-3 my-1" cleanable={false} onChange={(e) => { searchtitle(e) }} />
          <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="wv-100 ms-3 my-1">
            {
              [10, 20, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))
            }
          </Form.Select>
          <Link className="btn btn-primary ms-3 my-1" to="/News/Image">Add Image</Link>
          <Link className="btn btn-primary ms-3 my-1" to="/News/Add">Add New</Link>
        </div>
      </div>

      <div className="page-content">
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th width="5%">
                        <div className='table-sort-filter justify-content-center'>No.
                        </div>
                      </th>
                      <th width="85%">
                        <div className='table-sort-filter'>Name
                          <span className='table-sort'>
                            <div className={`sort-down ${iconcoror === "newsHeadlineId_down" ? "active" : ""}`} id="newsHeadlineId_down" onClick={(e) => { sorting('newsHeadlineId', "", "DSC", e) }} ></div>
                            <div className={`sort-up ${iconcoror === "newsHeadlineId_up" ? "active" : ""}`} id="newsHeadlineId_up" onClick={(e) => { sorting('newsHeadlineId', "", "ASC", e) }} ></div>
                          </span>
                        </div>
                      </th>
                      <th width="10%" className='text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      getData1(current, size).map((val, index) => {
                        return (
                          <tr key={index}>
                            <td className='text-center'>{id.indexOf(val._id) + 1}</td>
                            <td>{(val.newsHeadlineId !== null) ? val.newsHeadlineId.title : ""}</td>
                            <td className='text-center'>
                              <Link to={`/News/View/${val._id}`}>
                                <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                              </Link>
                              <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => { deletedata(val._id) }} ><i className='bx bx-trash-alt' ></i></Button>
                            </td>
                          </tr>
                        )
                      })
                    }
                    <tr id="remove_tr">

                    </tr>
                  </tbody>
                </Table>
                {(Data.length > size) ?
                  <div className="pagination-custom">
                    <Pagination showTitle={false}
                      className="pagination-data"
                      onChange={PaginationChange}
                      total={Data.length}
                      current={current}
                      pageSize={size}
                      showSizeChanger={false}
                      itemRender={PrevNextArrow}
                      onShowSizeChange={PerPageChange}
                    />
                  </div> : ""}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default NewsCategory