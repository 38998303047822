function Checkvalidator(category, headline, headlineimage,newsHeadline,news) {
    const newErrors = {}
    console.log(newsHeadline)
    if (category !== "") {
        if (category.name == undefined || category.name == "") {
            if (!category.name || category.name === '' || category.name == undefined) newErrors.name = 'Name is Required'
            // else if (!isNaN(data.name)) newErrors.name = 'Enter Valid Email'
        }

        if (category.image == undefined || category.image == "") {
            if (!category.image || category.image === '' || category.image == undefined) newErrors.image = 'Image is Required'
        }
    }

    if (headline !== "") {
        if (headline.newsCategory == undefined || headline.newsCategory == "") {
            if (!headline.newsCategory || headline.newsCategory === '' || headline.newsCategory == undefined) newErrors.newsCategory = 'NewsCategory is Required'
        }

        if (headline.title == undefined || headline.title == "") {
            if (!headline.title || headline.title === '' || headline.title == undefined) newErrors.title = 'Title is Required'
        }

        if (headline.description == undefined || headline.description == "") {
            if (!headline.description || headline.description === '' || headline.description == undefined) newErrors.description = 'Description is Required'
        }

        if (headline.news_url == undefined || headline.news_url == "") {
            if (!headline.news_url || headline.news_url === '' || headline.news_url == undefined) newErrors.news_url = 'News Url is Required'
        }

        if (headline.headtag == undefined || headline.headtag == "") {
            if (!headline.headtag || headline.headtag === '' || headline.headtag == undefined) newErrors.headtag = 'Headtag is Required'
        }

        if (headline.date == undefined || headline.date == "" || headline.date == "Invalid Date") {
            if (!headline.date || headline.date === '' || headline.date == undefined || headline.date == "Invalid Date") newErrors.date = 'Date is Required'
        }

        if(headlineimage === "") {
        if (headline.headlineImage == undefined || headline.headlineImage == "") {
            if (!headline.headlineImage || headline.headlineImage === '' || headline.headlineImage == undefined) newErrors.headlineImage = 'Headline Image is Required'
        }

        if (headline.websiteImage == undefined || headline.websiteImage == "") {
            if (!headline.websiteImage || headline.websiteImage === '' || headline.websiteImage == undefined) newErrors.websiteImage = 'Website Image is Required'
        }
        }
    }

    if (headlineimage !== "") {
        if (headlineimage.headline == undefined || headlineimage.headline == "") {
            if (!headlineimage.headline || headlineimage.headline === '' || headlineimage.headline == undefined) newErrors.headline = 'Headline Image is Required'
        }

        if (headlineimage.website == undefined || headlineimage.website == "") {
            if (!headlineimage.website || headlineimage.website === '' || headlineimage.website == undefined) newErrors.website = 'Website Image is Required'
        }
    }
    
    if(newsHeadline == "")
    {
        if (newsHeadline == "") {
            if (newsHeadline === '') newErrors.Headline = 'News Headline is Required'
        }
    }

    if(news == "")
    {
        if (news == "") {
            if (news === '') newErrors.news = 'News is Required'
        }
    }


    return newErrors
}

export default Checkvalidator