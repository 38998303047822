import React from 'react'
import { Container, NavLink, Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useEffect } from 'react';
import { API, Replaceurl } from '../App';
import { useState } from 'react';
import Page_not_found from './Page_not_found';
const Home = () => {

    var { title } = useParams()
    const [dataHook, SetDataHook] = useState(1)
    const [CategoryBy, SetCategoryBy] = useState()
    const [Slider, SetSlider] = useState([])
    const [News, SetNews] = useState()
    const Redirect = useNavigate()


    const getData = async (t) => {
        window.scrollTo(0, 0);
        const Form = new FormData()
        Form.append('title',(t !== "") ? t : title)
        const Response = await API.post('/NewsBytitle', Form)
        if (Response.data.response_message === "Data Not Found") {
            SetDataHook(0)
            console.log("Data Not Found")
        }
        else {
            SetCategoryBy(Response.data.category)
            SetSlider(Response.data.slider)
            SetNews(Response.data.NewsData)
            SetDataHook(1)
        }
    }

    const redirecturl = (url) => {
        Redirect(url)
    }

    const Dateformet = (dat) => {
        const monthname = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const date = new Date(dat)
        const month = monthname[date.getMonth('M')]
        const years = date.getFullYear()
        const day = date.getDate()
        var dateformte = day + " " + month + "," + " " + years
        return dateformte;
    }

    useEffect(() => {
        getData("")
    }, [])

    return (
        <>
            {(dataHook === 1) ?
                <>
                    <div className="news-details">
                        <Container className="p-0">
                            <Row>
                                <Col xs={12}>
                                    <div className="news-details-media">
                                        <a className="news-details-media-inner" href={`${(CategoryBy) ? CategoryBy.news_url : ""}`} onClick={() => { getData(CategoryBy.news_url.replace("https://financecalculatortest.vasundharaapps.com/#/NewsView/","")) }}>
                                        {/* <a className="news-details-media-inner" href={`${(CategoryBy) ? CategoryBy.news_url : ""}`} onClick={() => { getData(CategoryBy.news_url.replace("http://localhost:3000/#/NewsView/","")) }}> */}
                                            <img className="news-details-img" src={(CategoryBy) ? CategoryBy.headlineImage : ""} />
                                        </a>
                                        <div className="news-details-content">
                                            <h3 className="news-details-title">{(CategoryBy) ? CategoryBy.title : ""}</h3>
                                        </div>
                                        <div className="news-details-content">
                                            <div className="news-details-date">
                                                {(CategoryBy) ? Dateformet(CategoryBy.date) : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="more-content-details">
                                        <div className="content" dangerouslySetInnerHTML={{ __html: (News) ? News.news.replaceAll(",", "") : "" }}></div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    { (Slider.length !== 0) ? <>
                    <div className='also-like p-0'>
                        <Container className='p-lg-0'>
                            <h2 className="title mb-3">You might also like</h2>
                        </Container>
                        <Container fluid className="p-0">
                            <Swiper
                                className="recent-web-slider px-3"
                                spaceBetween={30}
                                slidesPerView={2.5}
                                slideToClickedSlide={true}
                                breakpoints={{
                                    "@0.00": {
                                        slidesPerView: 1.5,
                                        spaceBetween: 16,
                                    },
                                    "@0.75": {
                                        slidesPerView: 2.5,
                                        spaceBetween: 20,
                                    },
                                    "@1.00": {
                                        slidesPerView: 3.5,
                                        spaceBetween: 24,
                                    },
                                }}
                            >
                                {
                                    Slider.map((val, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <a href={`${(val) ? val.news_url : ""}`} onClick={() => { getData(val.news_url.replace("https://financecalculatortest.vasundharaapps.com/#/NewsView/","")) }}>
                                                {/* <a href={`${(val) ? val.news_url : ""}`} onClick={() => { getData(val.news_url.replace("http://localhost:3000/#/NewsView/","")) }}> */}
                                                    <div className="recent-web-card" >
                                                        <div className="recent-web-media">
                                                            <img src={(val) ? val.headlineImage : ""} alt="Awesome Interface" title="Awesome Interface" className="img-fluid"/>
                                                        </div>
                                                        <div className="recent-web-content">
                                                            <p>{(val) ? val.title : ""}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </Container>
                    </div></> : <></>}
                </>
                :
                // <div className='d-flex justify-content-center align-items-center' style={{ width: "100%", height: "100vh" }} >
                //     <h1 style={{ color: "red" }}>Data Not Found</h1>
                // </div>
                <Page_not_found/>
            }
        </>
    )
}

export default Home