import React from 'react'
import { Row, Card, Col, Form, Button, Breadcrumb } from 'react-bootstrap';
import Select from 'react-select';
import Layout from '../../layout/Layout';
import { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from '../../App';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import Checkvalidator from '../Checkvalidator';


const NewsEdit = () => {
    const Redirect = useNavigate()
    const [loading, Setloading] = useState(false)
    const { id } = useParams()

    const [getnewsheadline, setnewsheadline] = useState()
    const [getheadline, setheadline] = useState([])
    const [title, settitle] = useState([])
    const [news, setnews] = useState()
    const [style, setstyle] = useState("none")
    const [errors, setErrors] = useState({})


    const getcategory = (e) => {
        setnewsheadline(e.target.value)
        if (!!errors[e.target.name]) setErrors({
            ...errors,
            [e.target.name]: null
        })
    }

    const getheadlinedata = async () => {
        const Form = new FormData()
        Form.append("title", "all")
        const Response = await API.post('/GetTitle', Form, { headers: { "Authorization": `Bearer ${Cookies.get('jwt')}` } })
        if (Cookies.get('jwt')) {
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redirect("/")
            }
            setheadline(Response.data.data)
        }
        else {
            Redirect("/")
        }

    }

    const getdata = async () => {
        const Form = new FormData()
        Form.append('id', id)
        const Response = await API.post('/EditNews', Form)
        setnewsheadline((Response.data.data.newsHeadlineId !== null) ? Response.data.data.newsHeadlineId._id : "")
        settitle((Response.data.data.newsHeadlineId !== null) ? Response.data.data.newsHeadlineId : "");
        setnews(Response.data.data.news.replaceAll(",", "\n"))
    }

    const changeinput = (e) => {
        setnews(e.target.value)
        if (!!errors[e.target.name]) setErrors({
            ...errors,
            [e.target.name]: null
        })
    }

    const save = async () => {

        if (Cookies.get('jwt')) {
            const jwt = Cookies.get('jwt')
            const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redirect('/')
            }
            else {
                const newErrors = Checkvalidator("", "", "",getnewsheadline,news)
                if (Object.keys(newErrors).length > 0) {
                    Setloading(false)
                    setErrors(newErrors)
                    console.log(newErrors)
                }
                else {
                    Setloading(true)
                    const Form = new FormData()
                    Form.append('id', id)
                    Form.append('newsheadline', getnewsheadline)
                    Form.append('news', news)

                    const Response = await API.post('/UpdateNews', Form)
                    if (Response.data.response_code === 200) {
                        Setloading(false)
                        toast.success(Response.data.response_message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 1000,
                            closeOnClick: true
                        });
                        Redirect(`/News/View/${id}`)
                    }
                    else {
                        Setloading(false)
                        toast.error(Response.data.response_message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 1000,
                            closeOnClick: true,
                        });
                    }
                }
            }
        } else {
            Redirect('/')
        }
    }

    useEffect(() => {
        getdata()
        getheadlinedata()
    }, [])

    return (
        <>
            {(loading === true) ? <div className="loader" ></div> : <></>}
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3>News Edit</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                        <Link to="/News">News Lists</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Edit News</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className="page-content">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor='Headline-name'>News Headline</Form.Label>
                                        <Form.Select id="Headline-name" className="my-2" name='Headline' onChange={(e) => { getcategory(e) }}  isInvalid={!!errors.Headline} required>
                                            <option value="">Select News Headline</option>
                                            {
                                                getheadline.map((val, index) => {
                                                    return (
                                                        <option value={val._id} selected={title.title === val.title ? true : false}  >{val.title}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>{errors.Headline}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>News</Form.Label>
                                        <Form.Control as="textarea" className="my-2" rows={20} value={news} onChange={(e) => { changeinput(e) }}  isInvalid={!!errors.news} required/>
                                        <Form.Control.Feedback type='invalid'>{errors.news}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="warning" className="me-3" onClick={() => { setstyle("block") }}>Preview</Button>
                            <Button variant="primary" className="me-3" onClick={save}>Save</Button>
                            <Link to={`/News/View/${id}`}>
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                    <Card style={{ display: `${style}` }} className="mt-4">
                        <Card.Body>
                            <Col>
                                    <div className="content" dangerouslySetInnerHTML={{__html: news}}></div>
                            </Col>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default NewsEdit