import React from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../Component/Logo';
import LogoMini from '../Component/Logo-mini';
const Sidebar = ({ sidebar }) => {
  return (
    <>
      <div className={sidebar ? "sidebar-wrapper active" : "sidebar-wrapper"}>
        <div className="sidebar-header">
          <div className="d-flex justify-content-between">
            <div className='sidebar-logo'>
              <Link to="/">
                <Logo />
                <LogoMini />
              </Link>
            </div>
            <div className="toggler">
              <Link to="" className="sidebar-hide d-xl-none d-block"><i className="bi bi-x bi-middle"></i></Link>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">

          <ul className="menu">
            {/* <li className="sidebar-item active" >
                            <NavLink to="/Home" className='sidebar-link'>
                              <i className='bx bxs-home'></i>
                              <span>Dashboard</span>
                            </NavLink>
                        </li> */}
            <li className="sidebar-item">
              <NavLink to="/Category" className='sidebar-link'  >
                <i className='bx bxs-category-alt'></i>
                <span>News Category</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/Headlines" className='sidebar-link'>
                <i className='bx bx-news' ></i>
                <span>News Headlines</span>
              </NavLink>
            </li>
            <li className="sidebar-item" >
              <NavLink to="/News" className='sidebar-link'>
                <i className='bx bxs-news' ></i>
                <span>News</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <button className="sidebar-toggler btn x"><i data-feather="x"></i></button>
      </div>
    </>
  )
}

export default Sidebar