import React from 'react'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import { API } from '../App';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Card } from "react-bootstrap";

const Login = () => {

  const Redirect = useNavigate()

  const [Data, SetData] = useState({
    Email: Cookies.get('email') ? Cookies.get('email') : "",
    Password: Cookies.get('password') ? Cookies.get('password') : "",
    Remeber : Cookies.get('email') && Cookies.get('password') ? true : false
  })


  const inputdata = (e) => {
    SetData({ ...Data, [e.target.name]: e.target.value })
  }

  const Remeber = (e) => {
    SetData({Email:Data.Email,Password:Data.Password,Remeber:e.target.checked})
  }

  const login = async (e) => {
    e.preventDefault()
    if(Data.Remeber === false)
    {
      Cookies.remove('email')
      Cookies.remove('password')
    }
    else
    {
      Cookies.set('email', Data.Email)
      Cookies.set('password', Data.Password)
    }
    const Form = new FormData()
    Form.append('Email', Data.Email)
    Form.append('Password', Data.Password)
    const Response = await API.post("/login", Form)
    if (Response.data.response_code === 200) {
      toast.success(Response.data.response_message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
        closeOnClick: true
      });
      Cookies.set('jwt', Response.data.token, { expires: 1 })
      Cookies.set('name', Response.data.data.username, { expires: 1 })

      Redirect('/Category')
    }
    else {
      toast.error(Response.data.response_message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
        closeOnClick: true,
      });
    }
  }

  const checkAuth = async () => {
    if (Cookies.get('jwt')) {
      const jwt = Cookies.get('jwt')
      const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
      if (Response.data.response_message === "invalid token") {
        Cookies.remove('jwt')
        Redirect('/')
      }
      Redirect('/Category')
    } else {
      Redirect('/')
    }
  }

  useEffect(() => {
    checkAuth()
  }, [])


  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);

  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    }
    else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  }
  return (
    <>
      <Container>
        <div className='auth'>
          <div className='auth-box'>
            <Card>
              <Card.Header className="pb-0">
                <div className='auth-logo'>
                  <img src="../logo/app-icon.svg" alt="Vasundhara Infotech" className='logo-mini' />
                </div>
              </Card.Header>
              <Card.Body>
                <form method='post' onSubmit={login} >
                  <h1 className='auth-title'>Sign in</h1>
                  <h2 className='auth-subtitle'>Finance Calculator</h2>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" className="my-2" name="Email" id="" value={Data.Email} placeholder='Enter Your Email' onChange={(e) => { inputdata(e) }} />
                  </Form.Group>
                  <Form.Group className="mb-4 input-prefix">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type={password} className="my-2" name="Password" value={Data.Password} id="" placeholder='Enter Password' onChange={(e) => { inputdata(e) }} />
                    <i onClick={Eye} className={`bx ${eye ? "bx-hide" : "bx-show"}`}></i>
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Check type="checkbox" name="" id="" checked={Data.Remeber} label="Remember Me" onClick={e => Remeber(e)} />
                  </Form.Group>
                  <Button variant="primary" type='submit' className="w-100" >Sign In</Button>
                </form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Login