import React, { useEffect } from 'react'
import { Row, Col, Card, Form, Button, Breadcrumb } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { API } from '../../App';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';

const NewsAdd = () => {

    const Redirect = useNavigate()
    const [loading,Setloading] = useState(false)
    const [file, setfile] = useState("")

    const getfiles = (e) => {
        setfile(e.target.files[0])
    }

    const save = async () => {

        if (Cookies.get('jwt')) {
            const jwt = Cookies.get('jwt')
            const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redirect('/')
            }
            else {
                if(file === "" || file === undefined)
                {
                    toast.error("News Images Field Is Required", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 1000,
                        closeOnClick: true
                    });
                }
                else
                {
                Setloading(true)
                const Form = new FormData()
                Form.append('newsimage', file)
                const Response = await API.post('/Newsimage', Form)
                if (Response.data.response_code === 200) {
                    Setloading(false)
                    toast.success(Response.data.response_message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 1000,
                        closeOnClick: true
                    });
                    Redirect('/News/Image')
                }
                else {
                    Setloading(false)
                    toast.error(Response.data.response_message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 1000,
                        closeOnClick: true,
                    });
                }
              }
            }
        } else {
            Redirect('/')
        }
    }

    const checkAuth = async () => {
        if (Cookies.get('jwt')) {
            const jwt = Cookies.get('jwt')
            const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redirect('/')
            }
        } else {
            Redirect('/')
        }
    }

    useEffect(() => {
        checkAuth()
    }, [])


    return (
        <>
        {(loading === true) ? <div className="loader" ></div> : <></>}
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>News Image Add</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                    <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                    <Link to="/News/Image">News Image Lists</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Create News Image</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col sm={4}>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="cat_name" >Image</Form.Label>
                                    <Form.Control id="cat_name" type="file" onChange={(e) => { getfiles(e) }} className="my-2" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="text-end">
                        <Button variant="primary" className="me-3" onClick={save}>Save</Button>
                        <Link to="/News/Image">
                            <Button variant="secondary">Cancel</Button>
                        </Link>
                    </Card.Footer>
                </Card>
            </div>
        </Layout>
        </>
    )
}

export default NewsAdd