import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../../layout/Layout'
import Fancybox from '../../Component/FancyBox';
import Cookies from 'js-cookie';
import { API } from '../../App';
import { SelectPicker } from 'rsuite';


const CategoryView = () => {
    const { id } = useParams()
    const [Data, setData] = useState([])
    const [name, setname] = useState([])
    const Redireact = useNavigate()
    const [getid, setid] = useState({
        _id: id
    })

    const GetCategoryname = async () => {
        var name = []
        const Response = await API.post('/getcategoryname')
        Response.data.data.map((val, index) => {
            name.push({ label: val.name, value: val._id })
        })
        setname(name)
    }

    const getdata = async (id) => {
        if (Cookies.get('jwt')) {
            const jwt = Cookies.get('jwt')
            const Response = await API.post('/tokencheck', {}, { headers: { "Authorization": `Bearer ${jwt}` } })
            if (Response.data.response_message === "invalid token") {
                Cookies.remove('jwt')
                Redireact('/')
            }
            else {
                const Form = new FormData()
                Form.append('id', (id !== "") ? id : getid._id)
                const Response = await API.post('/ViewNewsCategory', Form)
                setData(Response.data.data)
            }
        } else {
            Redireact('/')
        }
    }


    const getinputname = (e) => {
        setid({ _id: e })
        getdata(e)
    }

    useEffect(() => {
        getdata("")
        GetCategoryname()
    }, [])

    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/Category" className='btn btn-primary btn-icon me-3'><i className='bx bxs-left-arrow-alt'></i></Link><span>View News Category</span></h3>
                <div className="page-heading-right">
                    <SelectPicker data={name} defaultValue={getid._id} placeholder="Select Your Name" className="wv-225 ms-3 my-1" cleanable={false} onChange={(e) => { getinputname(e) }} />
                    <Link to={`/Category/Edit/${getid._id}`}>
                        <Button variant="primary ms-3 my-1" value="edit" >Edit</Button>
                    </Link>
                </div>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Name</p>  <span>{Data.name}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Image</p>
                                    <Fancybox>
                                        <a data-fancybox="gallery" href={Data.image}>
                                            <img src={Data.image} className='hv-40 rounded-3' alt="avatar" />
                                        </a>
                                    </Fancybox>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Status</p>  <span>{(Data.status === 1) ? "Active" : "Deactive"}</span>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default CategoryView